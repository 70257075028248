import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout/main';
import PageTitle from 'components/page/title';
import Image from 'components/image/image';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { sortByPath } from 'components/utility/data';

const CeramicsProcessPage = (props) => {

    const { data } = props;

    const images = data.allImages.nodes.sort(sortByPath);
    const imgMargin = 1;

    return (
        <Layout containerSize="lg">
            <PageTitle navOffset>
                <h1>Process</h1>
            </PageTitle>
            <Box mt={3} display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    <Box m={imgMargin}>
                        <Image height={300} width="auto" src={images[0].publicURL} />
                    </Box>
                    <Box m={imgMargin}>
                        <Image height={300} width="auto" src={images[1].publicURL} />
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    <Box m={imgMargin}>
                        <Image height={300} width="auto" src={images[2].publicURL} />
                    </Box>
                    <Box m={imgMargin}>
                        <Image height={300} width="auto" src={images[3].publicURL} />
                    </Box>
                </Box>
            </Box>
            <Box mt={4}>
                <Container maxWidth="md">
                    <p>
                        It all starts on the wheel with a delicate dance of manipulating clay on a spinning
                        plate. There is of course a considerable amount of effort just to get the clay to
                        this point, but here is the opportunity to take a ball of material and turn it into
                        some of the most iconic symbols in our society.
                    </p>
                    <p>
                        I have always been taught that before starting a piece on the wheel, you need to
                        know what you want to make first. This will fundamentally drive your initial steps
                        of forming and shaping your piece. The series of images above are snapshots of the
                        process of creating a bottle form on a wheel.
                    </p>
                </Container>
            </Box>
        </Layout>
    );
}

export const query = graphql`
    query {
        allImages: allFile(filter: {relativePath: { glob: "ceramics/process/*.jpg" }}) {
          nodes {
            publicURL
            relativePath
            childImageSharp {
                fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
    }
`;

export default CeramicsProcessPage;